/* eslint-disable react/no-array-index-key */
const React = require('react');
const PropTypes = require('prop-types');
const { loadable, LazyHydrate } = require('nordic/lazy');
const WithTracker = require('../withTracker');
const ComponentPrints = require('@services/Tracking/resources/ComponentPrints');
const { componentListSpecialImport } = require('@utils/componentList');
const { toCamelCase, hasImportError } = require('@utils/helpers');

/**
 *
 * @param {*} id
 * @returns sanitized component name
 * This is a workaround due nordic rules with bundle names
 */
const bundleName = id => {
  return hasImportError(toCamelCase(id), componentListSpecialImport) ? toCamelCase(id) : id;
};

const Component = loadable(props => import(`@utils/componentList/${bundleName(props.id)}`));

const WithTrackerComponent = WithTracker(Component);

const Reader = ({ landingData, webView, deviceType, componentList }) => {
  const availableDevice = webView ? 'webview' : deviceType;
  const {
    url,
    site: { analytics },
    components,
  } = landingData;

  // TODO: Remove this when carousel ui-library_ml is no longer used
  const noLazyHydrateComponents = [
    'CarouselDynamic',
    'Carousel',
    'MainSlider',
    'MainSliderPlacement',
    'ImageCarousel',
    'AboutCarousel',
    'Slides',
    'DataViewer',
    'BannerPlacement',
    'BannerMPlay',
  ];
  const SSROnlyComponents = ['SponsorBanner', 'DataViewer'];

  return (
    <>
      {components.map((component, index) => {
        const {
          availableDeviceTypes,
          component_name,
          data: { disableLazy },
        } = component;

        if (!componentList.includes(bundleName(component_name))) return null;

        if (availableDeviceTypes && !availableDeviceTypes[availableDevice]) return null;
        const track = () => ComponentPrints.sendPrint(component, analytics, webView);

        if (SSROnlyComponents.includes(component_name))
          return (
            <LazyHydrate ssrOnly key={`${component_name}[${index}]`}>
              <WithTrackerComponent
                index={index}
                id={component_name}
                /* istanbul ignore next */
                track={track}
                component={component}
                analytics={analytics}
                webView={webView}
              />
            </LazyHydrate>
          );

        if (index < 2 || noLazyHydrateComponents.includes(component_name) || disableLazy)
          return (
            <WithTrackerComponent
              key={`${component_name}[${index}]`}
              index={index}
              id={component_name}
              /* istanbul ignore next */
              track={track}
              component={component}
              analytics={analytics}
              webView={webView}
            />
          );

        return (
          <LazyHydrate whenVisible key={`${component_name}[${index}]`}>
            <WithTrackerComponent
              index={index}
              id={component_name}
              /* istanbul ignore next */
              track={track}
              component={component}
              analytics={analytics}
              webView={webView}
            />
          </LazyHydrate>
        );
      })}
    </>
  );
};

Reader.propTypes = {
  landingData: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.shape({})),
    site: PropTypes.shape({
      analytics: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  webView: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  componentList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

module.exports = Reader;
